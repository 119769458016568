<template>
  <blockquote class="bg-primary text-secondary rounded p-4">
    <div class="flex items-start justify-between">
      <div>
        <p class="font-semibold">
          {{ review.customer }}
        </p>
        <p v-if="review.date" class="text-sm">
          {{ $format.date(review.date) }}
        </p>
      </div>

      <div v-if="review.featured" class="text-secondary opacity-60">
        <b-tooltip text="This review has been featured">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><path d="M235.32,81.37,174.63,20.69a16,16,0,0,0-22.63,0L98.37,74.49c-10.66-3.34-35-7.37-60.4,13.14a16,16,0,0,0-1.29,23.78L85,159.71,42.34,202.34a8,8,0,0,0,11.32,11.32L96.29,171l48.29,48.29A16,16,0,0,0,155.9,224c.38,0,.75,0,1.13,0a15.93,15.93,0,0,0,11.64-6.33c19.64-26.1,17.75-47.32,13.19-60L235.33,104A16,16,0,0,0,235.32,81.37ZM224,92.69h0l-57.27,57.46a8,8,0,0,0-1.49,9.22c9.46,18.93-1.8,38.59-9.34,48.62L48,100.08c12.08-9.74,23.64-12.31,32.48-12.31A40.13,40.13,0,0,1,96.81,91a8,8,0,0,0,9.25-1.51L163.32,32,224,92.68Z"></path></svg>
        </b-tooltip>
      </div>
    </div>
    <client-only>
      <star-rating
        inline
        :active-color="$site.colors.action.background"
        :inactive-color="$site.colors.content['background-2']"
        :show-rating="false"
        :read-only="true"
        :rating="(review.rating !== null) ? parseInt(review.rating) : ((review.recommend) ? 5 : 0)"
        :star-size="24"
        class="mb-3 mt-3"
      />
    </client-only>
    <p v-if="review.employee && review.service" class="text-muted mb-0">
      <small>{{ $t('general.serviceWithEmployee', {service: review.service, employee: review.employee}) }}</small>
    </p>
    <p v-else-if="review.service" class="text-muted mb-0">
      <small>{{ review.service }}</small>
    </p>
    <div class="mt-2 mb-0">
      <b-truncate
        v-if="review.comment"
        :clamp="$t('general.more')"
        :less="$t('general.showLess')"
        :text="review.comment"
        :length="80"
        action-class="text-sm block mt-2 font-semibold"
        rounded-corners
      />
    </div>
    <!--<div class="text-muted font-italic">
      <p class="mb-0">
        <small style="font-size: 0.8rem">{{ review.customer }}</small>
      </p>
    </div>-->

    <div v-if="review.reply" class="speech-bubble position-relative bg-secondary rounded p-3 mt-3">
      <p class="text-muted font-weight-bold mb-0" style="font-size: 1rem">
        <small>Reply from {{ $site.location.title }}</small>
      </p>
      <p class="mb-0" style="font-size: 1rem">
        {{ review.reply }}
      </p>
    </div>
  </blockquote>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
      required: true
    }
  }
}
</script>
