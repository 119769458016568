import get from 'lodash/get'
// import defaultTheme from 'tailwindcss/defaultTheme'

export default {
  head () {
    const meta = [
      { name: 'theme-color', content: this.$site.colors.action.background },
      { name: 'apple-mobile-web-app-title', content: this.$site.location.title },
      { name: 'application-name', content: this.$site.location.title },
      { name: 'og:site_name', content: this.$site.location.title }
    ]

    if (this.$site.settings?.bookappDescription) {
      meta.push({ name: 'description', content: this.info || this.$site.settings.bookappDescription })
      meta.push({ name: 'og:description', content: this.info || this.$site.settings.bookappDescription })
      meta.push({ name: 'twitter:description', content: this.info || this.$site.settings.bookappDescription })
    }

    if (this.$site.settings?.indexingEnabled === false) {
      meta.push({ name: 'robots', content: 'noindex' })
    }

    if (this.$site.integrations.b6359c5ca743483880dee4ffbae2fe95 && this.$site.integrations.b6359c5ca743483880dee4ffbae2fe95.enabled && this.$site.integrations.b6359c5ca743483880dee4ffbae2fe95.data && this.$site.integrations.b6359c5ca743483880dee4ffbae2fe95.data.domainVerificationCode) {
      meta.push({ name: 'facebook-domain-verification', content: this.$site.integrations.b6359c5ca743483880dee4ffbae2fe95.data.domainVerificationCode })
    }

    const link = []

    if (this.$site.images.icon) {
      if (this.$site.images.icon['180']) { link.push({ rel: 'apple-touch-icon', href: this.$site.images.icon['180'] }) }

      if (this.$site.images.icon['16']) { link.push({ rel: 'icon', sizes: '16x16', href: this.$site.images.icon['16'] }) }
      if (this.$site.images.icon['32']) { link.push({ rel: 'icon', sizes: '32x32', href: this.$site.images.icon['32'] }) }
      if (this.$site.images.icon['96']) { link.push({ rel: 'icon', sizes: '96x96', href: this.$site.images.icon['96'] }) }
      if (this.$site.images.icon['192']) { link.push({ rel: 'icon', sizes: '192x192', href: this.$site.images.icon['192'] }) }
    }
    
    const fonts = ['Libre Baskerville', 'Lato']

    for (const font of fonts) {
      link.push({ rel: 'stylesheet', href: `https://fonts.googleapis.com/css2?family=${font}` })
    }
    

    if (this.openGraphProperties) {
      const keys = Object.keys(this.openGraphProperties)
      for (const key of keys) {
        meta.push({ property: key, content: this.openGraphProperties[key] })
      }
    }

    if (this.twitterCardProperties) {
      const keys = Object.keys(this.twitterCardProperties)
      for (const key of keys) {
        meta.push({ name: key, content: this.twitterCardProperties[key] })
      }
    }

    const locale = this.$site.location.locale
    let lang = null
    if (locale) {
      const localeParts = locale.split('_')
      if (localeParts.length > 0) {
        lang = localeParts[0]
      }
    }

    let dir = 'ltr'
    if (this.$i18n && this.$i18n.locales && this.$i18n.locale) {
      const active = this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })

      if (active && active.dir === 'rtl') {
        dir = 'rtl'
      }
    }

    const scripts = []

    if (!this.$site.isEmbed) {
      scripts.push({ hid: 'ldjson-schema', innerHTML: JSON.stringify(this.structuredData), type: 'application/ld+json' })
    }

    return {
      htmlAttrs: {
        lang
      },
      bodyAttrs: {
        dir
      },
      title: (this.title) ? (this.title + ' - ' + this.$site.location.title) : this.$site.location.title,
      script: scripts,
      style: [{ hid: 'root-styles', innerHTML: this.siteStyles, type: 'text/css' }],
      meta,
      link,
      __dangerouslyDisableSanitizersByTagID: {
        'ldjson-schema': ['innerHTML'],
        'root-styles': ['innerHTML']
      }
    }
  },
  scrollToTop: true,
  /* layout: function (context) {
    return context.store.getters.layout
  }, */
  /* transition (to, from) {
    const order = [
      '/',
      '/home',
      '/bot',

      '/book-now',
      '/timetable',
      '/last-minute',
      '/gift',
      '/gallery',
      '/reviews',

      '/customer',
      '/customer/reservation',
      '/customer/sale',
      '/customer/sale/payment',
      '/customer/waitlist',

      '/book-now/availability',
      '/book-now/detail',
      '/book-now/customer',
      '/book-now/confirm',
      '/book-now/complete',

      '/gift/card',
      '/gift/card/customer',
      '/gift/card/confirm',
      '/gift/card/complete',

      '/gift/pass',
      '/gift/pass/customer',
      '/gift/pass/confirm',
      '/gift/pass/complete'
    ]

    const toIndex = order.indexOf(to.path)
    const fromIndex = (from) ? order.indexOf(from.path) : -1

    let direction = 'right'

    if (fromIndex >= 0) {
      direction = (toIndex > fromIndex) ? 'left' : 'right'
    }

    return {
      mode: 'out-in',
      name: 'slide-' + direction
    }
  }, */
  computed: {
    layoutRouteReplace () {
      const layout = this.$store.getters.layout
      return (layout === 'bot')
    },
    structuredData () {
      const contactData = this.$site.tabs.contact

      const data = {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        name: (contactData && contactData.contact.business) ? contactData.contact.business : this.$site.location.title
      }

      if (this.$site.images.logo) {
        data.image = this.$site.images.logo.large
      }

      if (contactData) {
        if (contactData.contact.phone) {
          data.telephone = contactData.contact.phone
        }

        if (contactData.contact.website) {
          data.url = contactData.contact.website
        }

        if (contactData.address) {
          data.address = {
            '@type': 'PostalAddress',
            streetAddress: contactData.address.street,
            addressLocality: contactData.address.city,
            addressRegion: contactData.address.region,
            postalCode: contactData.address.postcode,
            addressCountry: contactData.address.country
          }

          data.geo = {
            '@type': 'GeoCoordinates',
            latitude: contactData.address.coord.lat,
            longitude: contactData.address.coord.long
          }
        }
      }

      if (this.$site.tabs.review) {
        if (this.$site.tabs.review.stats.count > 0) {
          data.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: this.$site.tabs.review.stats.average,
            reviewCount: this.$site.tabs.review.stats.count,
            bestRating: 5,
            worstRating: 1
          }
        }

        if (this.$site.tabs.review.latest.length > 0) {
          const latestReviews = []

          for (let i = 0; i < this.$site.tabs.review.latest.length; i++) {
            const review = this.$site.tabs.review.latest[i]

            latestReviews.push({
              '@type': 'Review',
              author: (review.customer) ? review.customer : this.$t('general.anonymous'),
              datePublished: review.date,
              reviewBody: review.comment,
              reviewRating: {
                '@type': 'Rating',
                ratingValue: (review.rating) ? review.rating : ((review.recommend) ? 5 : 1)
              }
            })
          }

          data.review = latestReviews
        }
      }

      const sameAs = []
      if (this.$site.tabs.facebook && this.$site.tabs.facebook.pageID) {
        sameAs.push('https://facebook.com/' + this.$site.tabs.facebook.pageID)
      }
      if (this.$site.tabs.twitter && this.$site.tabs.twitter.username) {
        sameAs.push('https://twitter.com/' + this.$site.tabs.twitter.username)
      }

      if (sameAs.length > 0) {
        data.sameAs = sameAs
      }

      return data
    },
    openGraphProperties () {
      const contactData = this.$site.tabs.contact

      const properties = {
        'og:site_name': 'BookApp',
        'og:type': 'website',
        'og:title': (contactData && contactData.contact.business) ? contactData.contact.business : this.$site.location.title,
        'og:url': 'https://' + this.$site.host
        // 'og:image': (this.$site.images && this.$site.images.logo) ? this.$site.images.logo.medium : (this.$site.images && this.$site.images.hero) ? this.$site.images.hero.medium : ''
      }

      if (this.$site.images && this.$site.images.logo) {
        properties['og:image'] = this.$site.images.logo.large
      } else if (this.$site.images && this.$site.images.hero) {
        properties['og:image'] = this.$site.images.hero.large
      }

      return properties
    },
    twitterCardProperties () {
      const contactData = this.$site.tabs.contact

      const properties = {
        'twitter:card': 'summary',
        'twitter:title': (contactData && contactData.contact.business) ? contactData.contact.business : this.$site.location.title
        // 'twitter:image': (this.$site.images && this.$site.images.logo) ? this.$site.images.logo.medium : (this.$site.images && this.$site.images.hero) ? this.$site.images.hero.medium : ''
      }

      if (this.$site.tabs.twitter && this.$site.tabs.twitter.username) {
        properties['twitter:site'] = '@' + this.$site.tabs.twitter.username
      }

      return properties
    },
    siteStyles () {
      const rootVars = {}

      const Color = require('color')

      const colours = this.$site.colors

      const format = (key, { lighten, darken } = {}) => {
        const colour = get(colours, key)
        const trimmed = colour.trim()

        try {
          let output = Color(trimmed)

          if (lighten) {
            output.lighten(lighten)
          }

          if (darken) {
            output.darken(darken)
          }

          return output
            .rgb()
            .array()
            .join(' ')
        } catch (e) {
          return '255 0 0'
        }
      }

      rootVars['--bookapp-hero-background-color'] = this.$site.colors.header.background
      rootVars['--bookapp-hero-background-opacity'] = this.$site.colors.header.opacity
      rootVars['--bookapp-hero-background-image-large'] = this.$site.images.heroImageCssUrl('large')
      rootVars['--bookapp-hero-background-image-medium'] = this.$site.images.heroImageCssUrl('medium')
      rootVars['--bookapp-hero-background-image-small'] = this.$site.images.heroImageCssUrl('small')
      rootVars['--bookapp-hero-logo-image'] = this.$site.images.logoImageCssUrl('large')
      rootVars['--bookapp-hero-text-color'] = this.$site.colors.header.text

      rootVars['--bookapp-content-tint1-color'] = this.$site.colors.content['background-1']
      rootVars['--bookapp-content-tint2-color'] = this.$site.colors.content['background-2']
      rootVars['--bookapp-content-text-color'] = this.$site.colors.content.text

      rootVars['--bookapp-action-tint-color'] = this.$site.colors.action.background
      rootVars['--bookapp-action-text-color'] = this.$site.colors.action.text
      rootVars['--bookapp-action-tint-color-lighten-75'] = format('action.background', { lighten: 0.75 })
      rootVars['--bookapp-action-tint-color-lighten-50'] = format('action.background', { lighten: 0.5 })
      rootVars['--bookapp-action-tint-color-lighten-25'] = format('action.background', { lighten: 0.25 })

      // Contrast
      const action = Color(this.$site.colors.action.background?.trim())
      const background = Color(this.$site.colors.content?.['background-2'].trim())
      const contrast = action.contrast(background)

      // Text
      rootVars['--bookapp-text-primary'] = contrast <= 2 ? format('content.text') : format('action.background')
      rootVars['--bookapp-text-secondary'] = format('content.text')

      // Background
      rootVars['--bookapp-bg-primary'] = format('content.background-1')
      rootVars['--bookapp-bg-secondary'] = format('content.background-2')

      // Border colour
      rootVars['--bookapp-border-primary'] = format('content.background-1')
      rootVars['--bookapp-border-secondary'] = format('content.background-2')
      rootVars['--bookapp-border-action'] = format('action.background')
      rootVars['--bookapp-border-color'] = format('content.background-2', { lighten: 0.75 })

      // Action
      rootVars['--bookapp-bg-action'] = format('action.background')
      rootVars['--bookapp-text-action'] = format('action.text')

      // Border size/radius
      rootVars['--bookapp-border-width'] = '1px'
      rootVars['--bookapp-border-radius'] = get(colours, 'border.radius') !== null ? get(colours, 'border.radius') + 'rem' : '0.25rem'
      // rootVars['--bookapp-border-color'] = get(colours, 'border.colour') ? `rgba(${get(colours, 'border.colour')},0)` : 'rgba(0,0,0,.125)'

      if (get(colours, 'border.colour')) {
        const border = Color(get(colours, 'border.colour').trim())
        const rgb = border.array().join(',')

        rootVars['--bookapp-border-color'] = `rgba(${rgb},1)`
      } else {
        rootVars['--bookapp-border-color'] = 'rgba(0,0,0,.125)'
      }

      // Hero
      rootVars['--bookapp-bg-hero'] = format('header.background')
      rootVars['--bookapp-opacity-hero'] = this.$site.colors.header.opacity

      // Font
      rootVars['--bookapp-font-display'] = ['Lato'] // this.$site.fonts.family
      rootVars['--bookapp-font-sans'] = ['Lato']

      let styleString = ':root { '

      const keys = Object.keys(rootVars)
      for (const key of keys) {
        styleString += key + ': ' + rootVars[key] + ';'
      }

      styleString += ' }'
      return styleString
    }
  },
  methods: {
    layoutRouteTo (route) {
      if (this.layoutRouteReplace) {
        this.$router.replace(route)
      } else {
        this.$router.push(route)
      }
    },
    scrollTo (element, duration, options) {
      const layout = this.$store.getters.layout

      options = options || {}

      if (layout === 'bot') {
        options.container = document.getElementById('bot-content-container')
      }

      if (layout === 'default') {
        options.container = document.getElementById('main')
      }

      this.$scrollTo(element, duration, options)
    }
  }
}
